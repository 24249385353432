import * as React from 'react'

export const Undo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.25 16C15.25 16.4142 15.5858 16.75 16 16.75C16.4142 16.75 16.75 16.4142 16.75 16H15.25ZM2.46967 8.46967C2.17678 8.76256 2.17678 9.23744 2.46967 9.53033L7.24264 14.3033C7.53553 14.5962 8.01041 14.5962 8.3033 14.3033C8.59619 14.0104 8.59619 13.5355 8.3033 13.2426L4.06066 9L8.3033 4.75736C8.59619 4.46447 8.59619 3.98959 8.3033 3.6967C8.01041 3.40381 7.53553 3.40381 7.24264 3.6967L2.46967 8.46967ZM16.75 16C16.75 14.236 16.6274 12.8113 16.2201 11.6959C15.7944 10.5297 15.0641 9.71892 13.9422 9.19536C12.8708 8.69541 11.4711 8.47135 9.71908 8.36086C7.95568 8.24966 5.74077 8.25 3 8.25L3 9.75C5.75923 9.75 7.91932 9.75034 9.62467 9.85789C11.3414 9.96615 12.5042 10.1796 13.3078 10.5546C14.0609 10.9061 14.5181 11.4078 14.8111 12.2103C15.1226 13.0637 15.25 14.264 15.25 16H16.75Z"
      fill="currentColor"
    />
  </svg>
)
