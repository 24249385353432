import * as React from 'react'
import * as THREE from 'three'
import { Canvas, invalidate } from '@react-three/fiber'
import { useStore } from '@nanostores/react'
import { Channel, TColor } from '../../../types'
import { chartSettingsStore } from '../../../store/chartSettings'

import graphShader from './shader'

export function WebGLCanvas({
  width,
  height,
  channel,
  colors,
}: {
  colors: TColor[]
  width: number
  height: number
  channel: Channel
}) {
  const [material, setMaterial] = React.useState<THREE.ShaderMaterial | null>()
  const texture = React.useMemo(() => createColorsTexture(colors), [colors])
  const { showColors } = useStore(chartSettingsStore)

  const uniforms = React.useMemo(
    () => ({
      colors: { value: 0 as any, type: 't' },
      mode: { value: 0 },
      showColors: { value: false },
      resolution: { value: new THREE.Vector2(0, 0) },
    }),
    []
  )

  React.useEffect(() => {
    uniforms.colors.value = texture
    uniforms.mode.value = { l: 0, c: 1, h: 2 }[channel]
    uniforms.showColors.value = showColors

    const dpi = window.devicePixelRatio
    uniforms.resolution.value = new THREE.Vector2(width * dpi, height * dpi)

    if (!material) return
    material.needsUpdate = true
    invalidate()
  })

  return (
    <div style={{ width, height }}>
      <Canvas frameloop="demand">
        <mesh>
          <planeBufferGeometry attach="geometry" args={[width, height]} />
          <shaderMaterial
            attach="material"
            fragmentShader={graphShader}
            ref={ref => ref && setMaterial(ref)}
            uniforms={uniforms}
          />
        </mesh>
      </Canvas>
    </div>
  )
}

function createColorsTexture(colors: TColor[]): THREE.Texture {
  const width = colors.length
  const height = 1
  const size = width * height
  const data = new Float32Array(4 * size)

  colors.forEach((color, i) => {
    data[i * 4] = color.l
    data[i * 4 + 1] = color.c
    data[i * 4 + 2] = color.h
    data[i * 4 + 3] = 1
  })

  const texture = new THREE.DataTexture(
    data,
    width,
    height,
    THREE.RGBAFormat,
    THREE.FloatType,
    THREE.UVMapping,
    THREE.ClampToEdgeWrapping,
    THREE.ClampToEdgeWrapping,
    THREE.NearestFilter,
    THREE.NearestFilter,
    0
  )

  texture.needsUpdate = true
  return texture
}
