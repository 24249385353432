// @ts-ignore
import glsl from 'glslify'

export default glsl`
  #include <common>

  /*
  * Utilities for converting CIE L*C*h* to RGB.
  * Most of this code is ported right from chroma-js.
  */

  #define DEG2RAD 0.017453292519943295

  // Corresponds roughly to RGB brighter/darker
  #define Kn 18.0

  // D65 standard referent
  #define Xn 0.3127 / 0.329
  #define Yn 1.0
  #define Zn (1.0 - 0.3127 - 0.329) / 0.329

  #define t0 0.137931034
  #define t1 0.206896552
  #define t2 0.12841855
  #define t3 0.008856452

  vec3 lch2lab(vec3 lch) {
    float h = lch.z * DEG2RAD;
    return vec3(lch.x, cos(h) * lch.y, sin(h) * lch.y);
  }

  float xyz_rgb(float r) {
    return r <= 0.00304 ? 12.92 * r : 1.055 * pow(r, 1.0 / 2.4) - 0.055;
  }

  float lab_xyz(float t) {
    return t > t1
      ? t * t * t
      : t2 * (t - t0);
  }

  vec3 lab2rgb(vec3 lab) {
    float y = (lab.x + 16.0) / 116.0;
    float x = y + lab.y / 500.0;
    float z = y - lab.z / 200.0;

    y = Yn * lab_xyz(y);
    x = Xn * lab_xyz(x);
    z = Zn * lab_xyz(z);

    float r = xyz_rgb(3.2404542 * x - 1.5371385 * y - 0.4985314 * z); // D65 -> sRGB
    float g = xyz_rgb(-0.969266 * x + 1.8760108 * y + 0.041556 * z);
    float b = xyz_rgb(0.0556434 * x - 0.2040259 * y + 1.0572252 * z);
    return vec3(r, g, b);
  }

  vec3 lch2rgb(vec3 lch) {
    return lab2rgb(lch2lab(lch));
  }

  vec3 checker(vec2 px) {
    if((int(px.x / 5.0) + int(px.y / 5.0)) % 2 == 0) {
      return vec3(0.85);
    } else {
      return vec3(0.95);
    }
  }

  /**
   * Rendering the actual graph.
   */

  uniform sampler2D colors;
  uniform int mode;
  uniform bool showColors;
  uniform vec3 resolution;

  void mainImage(out vec4 rgba, vec2 xy) {
    vec2 uv = xy / resolution.xy;
    vec3 lch = texture2D(colors, vec2(uv.x, 0.0)).xyz;

    switch (mode) {
      // vertical -> luminance
      case 0:
        lch.x = uv.y * 100.0;
        break;
      // vertical -> chroma
      case 1:
        lch.y = uv.y * 134.0;
        break;
      // vertical -> hue
      case 2:
        lch.z = uv.y * 360.0;
        break;
    }

    vec3 srgb = lch2rgb(lch);

    if (srgb == clamp(srgb, -0.000005, 1.000005)) {
      if (showColors) {
        rgba = vec4(srgb, 1.0);
      } else {
        rgba = vec4(0.0, 0.0, 0.0, 1.0);
      }
    } else {
      rgba = vec4(checker(xy), 1.0);
    }
  }

  void main() {
    mainImage(gl_FragColor, gl_FragCoord.xy);
  }
`
